import React, { useEffect, useState } from "react";
import axios from "axios";

import { useHistory } from "react-router-dom";
import MultiStepForm from "./MultiStepForm";
import { useTranslation } from "react-i18next";
import { Stack } from "@fluentui/react";
import {
  getProvinceList,
  getDistrictList,
  getSubDistrictList,
} from "../providers/AreaData";

import {
  Button,
  Text,
  makeStyles,
  typographyStyles,
} from "@fluentui/react-components";
import {
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Spinner,
} from "@fluentui/react-components";

const API_URL = process.env.REACT_APP_API_URL;
const useStyles = makeStyles({
  breakTitle: {
    "@media (min-width: 768px)": {
      ...typographyStyles.subtitle1,
      fontWeight: "normal",
    },
    "@media (max-width: 768px)": {
      // Target screens below 768px
      ...typographyStyles.subtitle1,
      fontWeight: "normal",
    },
  },
  labeldata: typographyStyles.body1Stronger,
});
const centerItem = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const SummaryInformation = ({
  step,
  setStep,
  handlePrevStep,
  handleNextStep,
  setUniversaldata,
  urlID,
  isStepCompleted,
  formID,
  languageLabel,
  setLanguageLabel
}) => {
  const styles = useStyles();


  const { t, i18n } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  // Extract formData from location.state
  const formData = history.location.state?.formData || null;

  // console.log("formDATA", formData);

  const [branch, setBranch] = useState("")
  const [provincePrefix, setProvincePrefix] = useState("");
  const [districtPrefix, setDistrictPrefix] = useState("");
  const [subDistrictPrefix, setSubDistrictPrefix] = useState("");
  const [streetPrefix, setStreetPrefix] = useState("")
  const [province, setProvince] = useState({ key: "", text: "" });
  const [district, setDistrict] = useState({ key: "", text: "" });
  const [subDistrict, setSubDistrict] = useState({ key: "", text: "" });
  const [provinceKey, setProvinceKey] = useState(null);

  useEffect(() => {
    setProvince(formData.province)
    setDistrict(formData.district)
    setSubDistrict(formData.subdistrict)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (province.key) {
      const updatedText = getProvinceList(i18n.language).find(
        (p) => p.key === province.key
      )?.text;
      setProvince((prev) => ({ ...prev, text: updatedText || prev.text }));
    }
  }, [i18n.language, province.key]);

  useEffect(() => {
    if (district.key) {
      const updatedText = getDistrictList(province.key, i18n.language).find(
        (d) => d.key === district.key
      )?.text;

      console.log("Updated District Text:", updatedText);

      setDistrict((prev) => ({ ...prev, text: updatedText || prev.text }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, district.key]);

  useEffect(() => {
    if (subDistrict.key) {
      const updatedText = getSubDistrictList(district.key, i18n.language).find(
        (sd) => sd.key === subDistrict.key)?.text;

      console.log("sub district text", updatedText);

      setSubDistrict((prev) => ({ ...prev, text: updatedText || prev.text }))

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, subDistrict.key])


  useEffect(() => {
    setProvinceKey(formData.provinceKey)
    setStreetPrefix(formData.streetPrefix)
    setProvincePrefix(formData.provincePrefix)
    setDistrictPrefix(formData.districtPrefix)
    setSubDistrictPrefix(formData.subdistrictPrefix)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (provinceKey !== null) {
      setAreaPrefix(provinceKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceKey, i18n.language])


  useEffect(() => {
    if (i18n.language === "th") {
      setBranch(formData.branchTextThai)
    } else {
      setBranch(formData.branchTextEnglish)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language])


  const handleSubmit = async (formData, urlName) => {
    setLoading(true);
    const formDatasave = {
      "address": formData.address,
      "branchNumber": formData.branchNumber,
      "branchText": branch,
      "buildingNumber": formData.buildingNo,
      "streetPrefix": formData.streetPrefix,
      "streetName": formData.street,
      "districtPrefix": formData.districtPrefix,
      "districtCode": formData.district.key,
      "districtName": district.text,
      "email": formData.email,
      "name": formData.name,
      "phone": formData.tel,
      "phoneCountryCode": "+66",
      "postcode": formData.postcode,
      "provincePrefix": formData.provincePrefix,
      "provinceCode": formData.province.key,
      "provinceName": province.text,
      "subDistrictPrefix": formData.subdistrictPrefix,
      "subDistrictCode": formData.subdistrict.key,
      "subDistrictName": subDistrict.text,
      "taxId": formData.taxID,
      "taxNumberType": formData.taxNumberType,
      "note": formData.note,
      "taxInvAbbNumber": formData.taxInvAbbNumber
    };
    try {
      // Your form data here
      // formDatasave.created = new Date();

      // const headers = {
      //   "fid": `${formID}`,
      // };

      try {
        // const response = await axios.post(
        //   `http://localhost:5004/api/RequestsCreate`,
        //   formDatasave,
        //   { headers: headers }
        // );

        const response = await axios.post(
          `${API_URL}/clients/requests`,
          formDatasave,
          {
            headers: {
              "fid": `${formID}`,
            },
          }
        );

        // Handle the response data

        // If successful, navigate to the successful-save page
        setLoading(false);
        history.push({
          pathname: `/successful-save/${urlID}`,
          state: {
            formData,
            urlName,
          },
        });

        // Update the step

        setStep(3);
        handleNextStep();
        console.log(response);
      } catch (error) {
        history.push({
          pathname: `/requestfail-page/${urlID}`,
          state: {
            formData,
            urlName,
          },
        });
      }
    } catch (error) {
      // Handle errors
      console.error("There was a problem with the request:", error);
      history.push({
        pathname: `/requestfail-page/${urlID}`,
        state: {
          formData,
          urlName,
        },
      });
    }
  };

  const options = [
    { key: "TXID", text: t("addContact.txid") },
    { key: "NIDN", text: t("addContact.nidn") },
    { key: "CCPT", text: t("addContact.ccpt") },
  ];
  const getTextByKey = (key) => {
    const option = options.find((opt) => opt.key === key);
    return option ? option.text : "";
  };







  const setAreaPrefix = (_provinceCode) => {
    if (_provinceCode === "10") {
      if (i18n.language === "th") {
        setSubDistrictPrefix("แขวง");
        setDistrictPrefix("เขต");
        setProvincePrefix("");
        setStreetPrefix("ถ.")
      } else {
        setSubDistrictPrefix("");
        setDistrictPrefix("");
        setProvincePrefix("");
      }
    } else {
      if (i18n.language === "en") {
        setSubDistrictPrefix("");
        setDistrictPrefix("");
        setProvincePrefix("");
      } else {
        setSubDistrictPrefix("ต.");
        setDistrictPrefix("อ.");
        setProvincePrefix("จ.");
      }
    }
  };



  return (
    <>
      {loading ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100vh",

            zIndex: 1000,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {/* Spinner component */}
          <Spinner size="large" label={t("addContact.sentDataInprocess")} />
        </div>
      ) : (
        <div className="responsive-element">
          <form>
            <div className="header-part">
              {step === 2 && (
                <MultiStepForm
                  step={step}
                  setStep={setStep}
                  isStepCompleted={isStepCompleted}
                  languageLabel={languageLabel}
                  setLanguageLabel={setLanguageLabel}
                />
              )}
            </div>
            <div className="card-style-summary">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <div className="field-long-topic-summary">
                  <Text

                    style={{
                      fontSize: "1.3rem",
                      alignSelf: "center",
                      fontWeight: "bold",
                      position: "relative",
                    }}
                  >
                    {" "}
                    {t("addContact.emailForReceiving")}
                  </Text>
                </div>
                <div className="field-long-topic-summary-email">
                  <Text
                    className={styles.breakTitle}
                    style={{ textAlign: "center" }}
                  >
                    <div style={{ width: "100%", wordBreak: "break-all" }}>
                      {formData.email}
                    </div>
                  </Text>
                </div>
              </div>
            </div>

            <div className="card-style-summary">
              <Stack tokens={{ childrenGap: 10 }}>
                {/* Company Information */}
                <div className="field-long-summary">
                  <Text
                    style={{
                      fontSize: "1.3rem",
                      alignSelf: "center",
                      fontWeight: "bold",
                    }}
                  >
                    {t("addContact.personalInformation")}
                  </Text>
                </div>
                {/* Use a Flex container to align labels and data with consistent spacing */}
                <div className="label-and-data">
                  <Text style={{ width: "30%" }}>
                    <b className="label-of-summary-data">
                      {t("addContact.taxNumberType")} :
                    </b>
                  </Text>
                  <div
                    style={{
                      width: "70%",
                      textAlign: "left",
                    }}
                  >
                    <label className="label-of-summary-data">
                      {getTextByKey(formData.taxNumberType)}
                    </label>
                  </div>
                </div>
                <div className="label-and-data">
                  <Text style={{ width: "30%" }} className={styles.labeldata}>
                    <b className="label-of-summary-data">
                      {t("addContact.number")} :
                    </b>
                  </Text>
                  <div style={{ width: "70%", textAlign: "left" }}>
                    <label className="label-of-summary-data">
                      {formData.taxID}{" "}
                    </label>
                  </div>
                </div>
                {formData.taxNumberType === "TXID" &&
                  <div className="label-and-data">
                    <Text style={{ width: "30%" }} className={styles.labeldata}>
                      <b className="label-of-summary-data">
                        {t("addContact.branch")} :
                      </b>
                    </Text>
                    <div style={{ width: "70%", textAlign: "left" }}>
                      <label className="label-of-summary-data">
                        {formData.branchNumber === "00000"
                          ? branch
                          : formData.branchNumber}
                      </label>
                    </div>
                  </div>
                }
                <div className="label-and-data">
                  <Text style={{ width: "30%" }}>
                    <b className="label-of-summary-data">
                      {t("addContact.name")} :
                    </b>
                  </Text>
                  <div style={{ width: "70%", textAlign: "left" }}>
                    <label className="label-of-summary-data">
                      {formData.name}
                    </label>
                  </div>
                </div>
                {/* Address Information */}
                <div className="field-long-summary">
                  <Text
                    style={{
                      fontSize: "1.3rem",
                      alignSelf: "center",
                      fontWeight: "bold",
                      marginTop: "1.5rem",
                    }}
                  >
                    {t("addContact.addressForRequest")}
                  </Text>
                </div>
                <div className="label-and-data">
                  <Text style={{ width: "30%" }}>
                    <label className="label-of-summary-data">
                      <b>{t("addContact.address")} :</b>
                    </label>
                  </Text>
                  <div style={{ width: "70%", textAlign: "left" }}>
                    <Text style={{ textalign: "left" }}>
                      <label className="label-of-summary-data">
                        {formData.buildingNo} {formData.address}{" "}
                        {i18n.language === "th" && formData && formData.street ? (
                          <>
                            {streetPrefix}
                            {formData.street}
                          </>
                        ) : ""}
                        {" "}{i18n.language === "th" ? subDistrictPrefix : ""}
                        {subDistrict.text}{" "}
                        {i18n.language === "th" ? districtPrefix : ""}{district.text}{" "}
                        {i18n.language === "th" ? provincePrefix : ""}{province.text}{" "}
                        {formData.postcode}
                      </label>
                    </Text>
                  </div>
                </div>
                <div className="label-and-data">
                  <Text style={{ width: "30%" }}>
                    <label className="label-of-summary-data">
                      <b>{t("addContact.email")} :</b>
                    </label>
                  </Text>
                  <div
                    style={{
                      width: "70%",
                      textAlign: "left",
                    }}
                  >
                    <Text style={{ textalign: "left" }}>
                      <div style={{ width: "100%", wordBreak: "break-all" }}>
                        <label className="label-of-summary-data">
                          {formData.email}
                        </label>
                      </div>
                    </Text>
                  </div>
                </div>
                <div className="label-and-data">
                  <Text style={{ width: "30%" }}>
                    <label className="label-of-summary-data">
                      <b>{t("addContact.tel")} :</b>
                    </label>
                  </Text>
                  <div style={{ width: "70%", textAlign: "left" }}>
                    <Text style={{ textalign: "left" }}>
                      <label className="label-of-summary-data">
                        {formData.tel}
                      </label>
                    </Text>
                  </div>
                </div>
                {/* Additional Information */}
                <div className="label-and-data">
                  <Text style={{ width: "30%" }}>
                    <label className="label-of-summary-data">
                      <b>{t("addContact.description")} :</b>
                    </label>
                  </Text>
                  <div style={{ width: "70%", textAlign: "left" }}>
                    <Text style={{ textalign: "left" }}>
                      <label className="label-of-summary-data">
                        {formData.note}
                      </label>
                    </Text>
                  </div>
                </div>
                <div className="label-and-data">
                  <Text style={{ width: "30%" }}>
                    <label className="label-of-summary-data">
                      <b>{t("addContact.refNumber")} :</b>
                    </label>
                  </Text>
                  <div style={{ width: "70%", textAlign: "left" }}>
                    <Text style={{ textalign: "left" }}>
                      <label className="label-of-summary-data">
                        {formData.taxInvAbbNumber}
                      </label>
                    </Text>
                  </div>
                </div>
              </Stack>
            </div>
            <div>
              <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}>
                <Dialog modalType="modal">
                  <DialogTrigger disableButtonEnhancement>
                    <Button
                      appearance="primary"
                      style={{ marginRight: "10px" }}
                    >
                      {t("addContact.sentData")}
                    </Button>
                  </DialogTrigger>

                  <DialogSurface className="fluent-ui-dialog">
                    <DialogBody className="dialog-body">
                      <DialogTitle> {t("addContact.sentData")}</DialogTitle>
                      <DialogContent>
                        {t("addContact.confirmSentData")}
                      </DialogContent>
                      <DialogActions>
                        <div className="fluent-direction">
                          <div className="button-trigger">
                            <DialogTrigger disableButtonEnhancement>
                              <Button
                                onClick={() => {
                                  handleSubmit(formData, urlID);
                                }}
                                appearance="primary"
                                className="button-dialog"
                              >
                                {t("addContact.confirm")}
                              </Button>
                            </DialogTrigger>
                          </div>
                          <div className="button-trigger">
                            <DialogTrigger disableButtonEnhancement>
                              <Button
                                appearance="secondary"
                                className="button-dialog"
                              >
                                {t("addContact.cancel")}
                              </Button>
                            </DialogTrigger>
                          </div>
                        </div>
                      </DialogActions>
                    </DialogBody>
                  </DialogSurface>
                </Dialog>

                <Button
                  appearance="secendary"
                  style={{ marginRight: "10px", alignSelf: "center" }}
                  onClick={() => {
                    history.push({
                      pathname: `/${urlID}`,
                      state: {
                        ...formData,
                      },
                    });

                    setUniversaldata(true);
                    handlePrevStep();
                    setStep(1);
                  }}
                >
                  {t("addContact.back")}
                </Button>
              </Stack>

              <br />
              <br />
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default SummaryInformation;
