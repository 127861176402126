import React from "react";

import MultiStepForm from "./MultiStepForm";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Button } from "@fluentui/react-components";
import { Checkmark24Filled } from "@fluentui/react-icons";

const SuccessfulSave = ({
  step,
  setStep,
  urlID,
  savedData,
  isStepCompleted,
  setCompletedSteps,
  languageLabel,
  setLanguageLabel
}) => {
  const { t } = useTranslation();

  const history = useHistory();

  return (
    <div className="responsive-element">
      {/* Grey background overlay while loading */}

      <form>
        <div className="header-part">
          {step === 3 && (
            <MultiStepForm
              step={step}
              setStep={setStep}
              isStepCompleted={isStepCompleted}
              languageLabel={languageLabel}
              setLanguageLabel={setLanguageLabel}
            />
          )}


          <div className="card-style-successful">
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {" "}
              <div
                style={{
                  width: 55,
                  height: 55,
                  borderRadius: "50%",
                  // border: "1px solid #white",
                  display: "flex",

                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor: "rgba(37, 206, 44, 0.8)",
                  color: "white",
                  marginBottom: "1rem",
                  border: "1px solid white",
                  marginTop: "2rem",
                }}
              >
                <Checkmark24Filled />
                {/* Display checkmark if step completed */}
              </div>
              <div
                style={{
                  alignSelf: "center",
                  marginLeft: "10px",
                  fontSize: "25px",
                  marginBottom: "50px",
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                }}
              >
                {t("addContact.success")}
              </div>
              {savedData.previewTextSecond.length > 8 && (<div className="card-style-display-text">
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                    justifyContent: "center",
                    justifyItems: "center",
                    textAlign: "center",
                  }}
                >

                  <div
                    dangerouslySetInnerHTML={{
                      __html: savedData.previewTextSecond,
                    }}
                  ></div>

                </div>
              </div>
              )}
              <div className="responsive-boxbutton">
                <Button
                  appearance="primary"
                  style={{ marginTop: "50px" }}
                  onClick={() => {
                    history.push({
                      pathname: `/${urlID}`,
                    });
                    setCompletedSteps([]);
                    setStep(1);
                    isStepCompleted(1);
                  }}
                >
                  {t("addContact.backToHomePage")}
                </Button>

                <br />
                <br />

                <br />
                <br />
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SuccessfulSave;
