import "./App.css";
import AddContacts from "../src/component/AddContacts";
import { Stack } from "@fluentui/react";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";

import { useEffect } from "react";
import axios from "axios";
import { initializeIcons } from "@fluentui/react/lib/Icons";
import { setIconOptions } from "@fluentui/react/lib/Styling";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import SummaryInformation from "./component/SummaryInformation";
import { useState } from "react";
import SuccessfulSave from "./component/SuccessfulSave";
import { theme } from "./providers/ThemeColorsSetting.js";
import CloseFormPage from "./component/CloseFormPage";
import UnSuccess from "./component/UnSuccess";
import FullScreenSpinner from "./component/FullScreenSpinner";
import WrongUrl from "./component/WrongUrl";
import { DialogStatement } from "./component/DialogStatement.js";

const API_URL = process.env.REACT_APP_API_URL;
const version = process.env.REACT_APP_REQUEST_FORM_VERSION
function App() {
  initializeIcons(undefined, { disableWarnings: true });
  setIconOptions({ disableWarnings: true, });
  const [step, setStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([]); // Track completed steps
  const totalSteps = 3;

  const handleNextStep = () => {
    if (step < totalSteps) {
      setStep(step + 1);
      setCompletedSteps([...completedSteps, step]); // Mark current step as completed
    }
  };

  const handlePrevStep = () => {
    if (step > 1) {
      setStep(step - 1);
      // Remove the completed step when navigating back
      setCompletedSteps(
        completedSteps.filter((completedStep) => completedStep !== step - 1)
      );
    }
  };

  const isStepCompleted = (stepNumber) => {
    return completedSteps.includes(stepNumber);
  };

  const [isEnabled, setIsEnabled] = useState(true); // State to track form state
  const [loading, setLoading] = useState(true);
  const [urlID, setUrlID] = useState(null);
  const [formID, setFormID] = useState(null);
  const [urlwrong, setUrlWrong] = useState(false);
  const [policyMessage, setPolicyMessage] = useState("")
  const themeGetter = (id) => {
    return theme.find((theme) => theme.id === id);
  };
  useEffect(() => {
    async function fetchData() {
      try {
        setFormID(null);
        // setLoading(true);
        const response = await axios.get(`${API_URL}/clients/urlName/${urlID}`);

        if (response.data.enabled === false) {
          setLoading(true);
          setIsEnabled(false);
        } else {
          const fetchedFormID = response.data.id;
          setFormID(fetchedFormID);

          const themecurrent = themeGetter(response.data.setting.themeId);

          const root = document.documentElement;

          // Apply theme data to CSS variables
          if (themecurrent) {
            // setLoading(true);
            root.style.setProperty(
              "--background-color",
              themecurrent.backgroundColor
            );
            root.style.setProperty(
              "--primary-color",
              themecurrent.primaryColor
            );
            root.style.setProperty(
              "--secondary-color",
              themecurrent.secondaryColor
            );
            root.style.setProperty(
              "--accent-color-1",
              themecurrent.accentColor1
            );
          }

          setIsEnabled(true); // Update the form state based on the response
        }
        setSavedData(response.data.message);
        setPolicyMessage(response.data.privacyPolicyMessage)
      } catch (error) {
        console.error("Error fetching data:", error.message);
        setUrlWrong(true);
      } finally {
        setLoading(false);
      }
    }
    if (urlID) {
      fetchData();
    }
  }, [urlID]);

  useEffect(() => {
    if (urlwrong) {
      setLoading(false);
    }
  }, [urlwrong]);

  const [universaldata, setUniversaldata] = useState(false);

  const [savedData, setSavedData] = useState(null);

  const [previousPath, setPreviousPath] = useState(null);
  const [languageLabel, setLanguageLabel] = useState("TH")
  useEffect(() => {
    const hrefsplit = window.location.href.split("/");
    const urlName = hrefsplit[hrefsplit.length - 1];

    setUrlID(urlName);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      const currentPath = window.location.pathname;
      if (
        currentPath === `/summary-information/${urlID}` ||
        currentPath === `/successful-save/${urlID}`
      ) {
        setPreviousPath(`/${urlID}`);
        setCompletedSteps([]);
        setStep(1);
        isStepCompleted(1);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousPath]);


  const [onOpenDialog, setOnOpenDialog] = useState(true)
  const [acceptPolicy, setAcceptPolicy] = useState(false)
  return (
    <FluentProvider theme={webLightTheme}>
      <Router>
        <div className="App">
          {loading ? (
            <Route exact path={`/:urlget`}>
              <FullScreenSpinner />
            </Route>
          ) : (
            <Switch>
              <Stack
                style={{
                  display: "flex",
                  background: "#FAF9F8",
                  width: "100%",
                  flexDirection: "column",
                  minHeight: "100vh",
                }}
              >
                {isEnabled &&
                  (<DialogStatement
                    policyMessage={policyMessage}
                    onOpenDialog={onOpenDialog}
                    setOnOpenDialog={setOnOpenDialog}
                    acceptPolicy={acceptPolicy}
                    setAcceptPolicy={setAcceptPolicy}
                    languageLabel={languageLabel}
                    setLanguageLabel={setLanguageLabel}
                  />
                  )
                }
                <Route exact path={`/${urlID}`}>


                  {step === 1 ? (
                    <AddContacts
                      step={step}
                      setStep={setStep}
                      handleNextStep={handleNextStep}
                      handlePrevStep={handlePrevStep}
                      isStepCompleted={isStepCompleted}
                      universaldata={universaldata}
                      setUniversaldata={setUniversaldata}
                      urlID={urlID}
                      savedData={savedData}
                      Loading={loading}
                      policyMessage={policyMessage}
                      version={version}
                      setOnOpenDialog={setOnOpenDialog}
                      setAcceptPolicy={setAcceptPolicy}
                      languageLabel={languageLabel}
                      setLanguageLabel={setLanguageLabel}
                    />
                  ) : (
                    <Redirect to={`/${urlID}`} />
                  )}
                </Route>
                <Route exact path={`/summary-information/${urlID}`}>
                  {!isEnabled && urlID && (
                    <Redirect to={`/close-page/${urlID}`} />
                  )}

                  {step === 2 ? (
                    <SummaryInformation
                      step={step}
                      setStep={setStep}
                      handlePrevStep={handlePrevStep}
                      handleNextStep={handleNextStep}
                      totalSteps={totalSteps}
                      universaldata={universaldata}
                      setUniversaldata={setUniversaldata}
                      urlID={urlID}
                      isStepCompleted={isStepCompleted}
                      formID={formID}
                      languageLabel={languageLabel}
                      setLanguageLabel={setLanguageLabel}
                    />
                  ) : (
                    <Redirect to={`/${urlID}`} />
                  )}
                </Route>
                <Route exact path={`/successful-save/${urlID}`}>
                  {!isEnabled && urlID && !loading && (
                    <Redirect to={`/close-page/${urlID}`} />
                  )}

                  {step === 3 ? (
                    <SuccessfulSave
                      step={step}
                      setStep={setStep}
                      handlePrevStep={handlePrevStep}
                      handleNextStep={handleNextStep}
                      totalSteps={totalSteps}
                      urlID={urlID}
                      savedData={savedData}
                      isStepCompleted={isStepCompleted}
                      completedSteps={completedSteps}
                      setCompletedSteps={setCompletedSteps}
                      languageLabel={languageLabel}
                      setLanguageLabel={setLanguageLabel}
                    />
                  ) : (
                    <Redirect to={`/${urlID}`} />
                  )}
                </Route>
                <Route exact path={`/close-page/${urlID}`}>
                  {isEnabled && urlID && !loading && (
                    <Redirect to={`/${urlID}`} />
                  )}

                  <CloseFormPage
                    handlePrevStep={handlePrevStep}
                    handleNextStep={handleNextStep}
                    totalSteps={totalSteps}
                    savedData={savedData}
                    languageLabel={languageLabel}
                    setLanguageLabel={setLanguageLabel}
                  />
                </Route>
                <Route exact path={`/requestfail-page/${urlID}`}>
                  {isEnabled && urlID && !loading && (
                    <Redirect to={`/${urlID}`} />
                  )}

                  <UnSuccess
                    step={step}
                    setStep={setStep}
                    handlePrevStep={handlePrevStep}
                    handleNextStep={handleNextStep}
                    totalSteps={totalSteps}
                    urlID={urlID}
                    savedData={savedData}
                    isStepCompleted={isStepCompleted}
                    completedSteps={completedSteps}
                    setCompletedSteps={setCompletedSteps}
                  />
                </Route>
                {urlwrong && <Redirect to="/wrongurl" />}
                <Route exact path={`/wrongurl`}>
                  <WrongUrl
                    step={step}
                    setStep={setStep}
                    handlePrevStep={handlePrevStep}
                    handleNextStep={handleNextStep}
                    totalSteps={totalSteps}
                    urlID={urlID}
                    savedData={savedData}
                    isStepCompleted={isStepCompleted}
                    completedSteps={completedSteps}
                    setCompletedSteps={setCompletedSteps}
                  />
                </Route>
                {!isEnabled && urlID && (
                  <Redirect to={`/close-page/${urlID}`} />
                )}
              </Stack>
            </Switch>
          )}
        </div>
      </Router>
      {/* <PoweredBy /> */}
    </FluentProvider>
  );
}

export default App;
