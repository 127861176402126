export const translationTh = {
  "addContact": {
    "dropdownErrMsg": "กรุณาเลือก",
    "getErrorMessage": "กรุณากรอกข้อมูล",
    "getPostCodeErrorMessage1": "กรุณาระบุตัวเลขเท่านั้น",
    "getPostCodeErrorMessage2": "กรุณาระบุเลขให้ครบ 5 หลัก",
    "getTaxIdErrorMessage": "กรอกเลข 13 หลัก",
    "getBranchNumberErrorMessage1": "ระบุเป็นตัวเลขเท่านั้น",
    "getBranchNumberErrorMessage2": "กรอกเลข 5 หลัก",
    "getPhoneNumberErrorMessage": "รูปแบบไม่ถูกต้อง ตัวอย่าง: 0812345678",
    "documentHeader": "ใบเสร็จรับเงิน/ใบกำกับภาษี",
    "documentSubHeader": "Receipt/Tax Invoice",
    "original": "ต้นฉบับ",
    "companyTel": "โทร",
    "companyEmail": "อีเมล",
    "companyTaxId": "เลขประจำตัวผู้เสียภาษี",
    "customerName": "ชื่อลูกค้า",
    "pleaseSelect": "กรุณาเลือก",
    "othr": "ไม่ระบุ",
    "number": "เลขที่",
    "tel": "โทร",
    "priceIncludeVat": "ราคารวมภาษีมูลค่าเพิ่ม",
    "date": "วันที่",
    "pleaseSelectDate": "กรุณาเลือกวันที่",
    "reference": "อ้างอิง",
    "itemNumber": "ลำดับ",
    "item": "รายการ",
    "quantity": "จำนวน",
    "unit": "หน่วย",
    "unitPrice": "ราคา/หน่วย",
    "discount": "ส่วนลด",
    "itemVat": "ภาษี (%)",
    "totalIncludeVat": "มูลค่ารวม VAT(บาท)",
    "totalBeforeVat": "มูลค่าก่อนภาษี (บาท)",
    "totalErrorMessage1": "มูลค่าไม่สามารถติดลบได้",
    "delete": "ลบ",
    "addItem": "เพิ่มรายการ",
    "note": "หมายเหตุ",
    "receivedBy": "ผู้รับเงิน",
    "createdBy": "ผู้จัดทำ",
    "reIssueMessage1": "เป็นการยกเลิกและออกใบกำกับภาษีใหม่แทนใบกำกับภาษีเดิม",
    "amountTotalBeforeVat": "รวมเงิน (ก่อน Vat)",
    "amountTotal": "รวมเป็นเงิน",
    "thb": "บาท",
    "vat": "ภาษีมูลค่าเพิ่ม",
    "grandTotal": "จำนวนเงินทั้งสิ้น",
    "referNumber": "เลขที่ใบกำกับภาษีเดิม",
    "referDate": "วันที่ใบกำกับภาษีเดิม",
    "reason": "สาเหตุการยกเลิกใบกำกับภาษีเดิม",
    "specificReason": "ระบุสาเหตุ",
    "nonVatPriceTotal": "มูลค่ารายการยกเว้นภาษี",
    "zeroVatPriceTotal": "มูลค่ารายการภาษี 0%",
    "vatPriceTotal": "มูลค่ารายการภาษี 7%",
    "next": "ต่อไป",
    "confirm": "ยืนยัน",
    "cancel": "ยกเลิก",
    "sentData": "ส่งข้อมูล",
    "confirmSentData": "ยืนยันการส่งข้อมูล",
    "loading": "กำลังโหลด...",
    "isRequiredErrorMessage": "กรุณาเลือกวันที่",
    "taxIdError": "กรอกเลขประจำตัวผู้เสียภาษีให้ครบ 13 หลัก",
    "updateSuccess": "บันทึกสำเร็จ!",
    "updateFail": "บันทึกไม่สำเร็จ!",
    "namePlaceHolder": "บริษัท ผู้ขาย จำกัด",
    "addressForCreate": "ที่อยู่สำหรับออกเอกสาร",
    "email": "อีเมล",
    "contactForCustomer": "ลูกค้าติดต่อที่",
    "registerVat": "จดทะเบียนภาษีมูลค่าเพิ่ม",
    "registerVatYes": "ได้จดทะเบียน ภ.พ. 20",
    "registerVatNo": "ไม่ได้จดทะเบียน ภ.พ. 20",
    "saveSeparator": "กดปุ่มเพื่อบันทึกข้อมูล",
    "save": "บันทึก",
    "saving": "กำลังบันทึก...",
    "notDisplay": "ไม่แสดง",
    "alreadyHaveTaxId": "เลขประจำตัวผู้เสียภาษีมีในรายชื่อผู้ติดต่อแล้ว!",
    "alreadyHaveContact": "ผู้ติดต่อนี้ถูกใช้แล้ว!",
    "processing": "กำลังดำเนินการ...",
    "header": "เพิ่มผู้ติดต่อ",
    "headerDescription": "(ข้อมูลทั้งหมดข้างล่างนี้ ใช้สำหรับแสดงข้อมูลผู้ติดต่อบนเอกสารของท่าน)",
    "companyInfo": "ข้อมูลชื่อกิจการ",
    "taxNumberType": "ประเภท",
    "txid": "เลขประจำตัวผู้เสียภาษี (นิติบุคคล)",
    "nidn": "เลขประจำตัวประชาชน (บุคคลธรรมดา)",
    "ccpt": "เลขที่หนังสือเดินทาง",
    "english": "ภาษาอังกฤษ",
    "on": "เปิด",
    "off": "ปิด",
    "taxId": "เลขที่",
    "branchDisplay": "สาขา",
    "branchNumberDesc": "ตัวอย่าง 00001",
    "search": "ค้นหา",
    "companyName": "ชื่อบริษัท",
    "addressHeader": "ที่อยู่",
    "addressStructure": "โครงสร้างที่อยู่",
    "buildingNumber": "บ้านเลขที่",
    "address": "ที่อยู่",
    "streetPrefix": "คำนำหน้าถนน",
    "streetName": "ถนน",
    "provincePrefix": "คำนำหน้าจังหวัด",
    "province": "จังหวัด",
    "districtPrefix": "คำนำหน้าอำเภอ",
    "district": "อำเภอ (เลือกจังหวัดก่อน)",
    "districtPlus": "",
    "subDistrictPrefix": "คำนำหน้าตำบล",
    "subDistrict": "ตำบล (เลือกอำเภอก่อน)",
    "postcode": "รหัสไปรษณีย์",
    "lineOne": "ที่อยู่ บรรทัดที่ 1",
    "lineTwo": "ที่อยู่ บรรทัดที่ 2",
    "country": "ประเทศ",
    "contactHeader": "ช่องทางติดต่อ",
    "phone": "เบอร์โทร",
    "website": "เว็บไซต์",
    "fax": "เบอร์แฟกซ์",
    "contactHeader2": "ข้อมูลบุคคลที่ติดต่อได้",
    "contactName": "ชื่อผู้ติดต่อ",
    "contactNickName": "ชื่อเล่น",
    "contactEmail": "อีเมล",
    "contactPhone": "เบอร์โทร",
    "contactDepartment": "แผนก",
    "contactPosition": "ตำแหน่งงาน",
    "paymentHeader": "ข้อมูลธนาคารของคู่ค้า",
    "accountName": "ชื่อบัญชีธนาคาร",
    "bankName": "ธนาคาร",
    "bankBranch": "สาขา",
    "accountType": "บัญชีประเภท",
    "accountNumber": "บัญชีเลขที่",
    "paymentNote": "หมายเหตุการชำระเงิน",
    "addBank": "เพิ่มธนาคาร",
    "back": "กลับ",
    "getErrorMessageTaxIdZeroOnly": "ข้อมูลไม่ถูกต้อง",
    "firstStep": "ขั้นตอนที่ 1: กรอกข้อมูล",
    "secendStep": "ขั้นตอนที่ 2: ตรวจสอบข้อมูล",
    "pleaseCheck": "โปรดตรวจสอบข้อมูลให้ถูกต้องครบถ้วน",
    "thirdStep": "ขั้นตอนที่ 3: ส่งข้อมูลเรียบร้อยแล้ว",
    "done": "เสร็จสิ้น!",
    "ninText": "เลขประจำตัวประชาชน",
    "passportText": "เลขหนังสือเดินทางต่างประเทศ",
    "tinText": "เลขประจำตัวผู้เสียภาษี",
    "en": "TH",
    "success": "สำเร็จ !",
    "enterClient": "ข้อมูลผู้ซื้อ ",
    "fillDataRequest": "กรอกข้อมูลขอ",
    "fillDataRequest2": "ใบเสร็จรับเงินใบกำกับภาษี",
    "onlyName": "ชื่อ",
    "fullRequest": "แบบฟอร์ม",
    "fullRequest2": "ขอใบกำกับภาษีเต็มรูปแบบ",
    "pleaseSelectBranch": "กรุณาเลือกสาขา",
    "pleaseEnterYourEmail": "กรุณากรอกอีเมล",
    "pleaseCheckYourEmail": "กรุณาตรวจสอบอีเมล",
    "pleaseSelectTaxPayerType": "กรุณาเลือกประเภทผู้เสียภาษี",
    "pleaseEnterYourName": "กรุณากรอกชื่อ",
    "pleaseEnterTheNumber": "กรุณากรอกเลขที่",
    "pleaseEnterTheAddress": "กรุณากรอกที่อยู่",
    "pleaseSelectaProvince": "กรุณาเลือกจังหวัด",
    "pleaseSelectaDistrict": "กรุณาเลือกอำเภอ",
    "pleaseSelectaSubdistrict": "กรุณาเลือกตำบล",
    "headOffice": "สำนักงานใหญ่",
    "emailForReceiving": "อีเมลสำหรับรับใบกำกับภาษี",
    "branchNumber": "สาขาเลขที่",
    "personalInformation": "ข้อมูลบุคคล",
    "branch": "สาขา",
    "name": "ชื่อ",
    "firstNameLastName": "ชื่อ - นามสกุล",
    "addressForRequest": "ที่อยู่สำหรับขอออกใบกำกับภาษี",
    "description": "หมายเหตุ",
    "sentDataInprocess": "กำลังส่งข้อมูล",
    "dataNotSent": " ขออภัย ส่งข้อมูลไม่สำเร็จ",
    "pleaseCheckData": "โปรดตรวจสอบข้อมูล",
    "retrySentData": "ลองส่งข้อมูลใหม่อีกครั้ง",
    "formNotFound": "ไม่พบฟอร์มที่คุณต้องการ",
    "checkUrl": "โปรดตรวจสอบ URL",
    "retryScan": "ลองสแกน QR CODE อีกครั้ง",
    "cantFillForm": "แบบฟอร์มในขณะนี้ไม่สามารถกรอกข้อมูลได้",
    "formConditionFooter": "แบบฟอร์มขอใบกำกับภาษีเต็มรูปแบบนี้ ถูกเปิดใช้งานโดยผู้ใช้บริการบนซอฟต์แวร์ Leceipt ข้อมูลที่คุณส่งจะถูกส่งไปยังผู้ใช้บริการรายนี้ โดย Leceipt จะไม่รับผิดชอบต่อความเป็นส่วนตัวหรือแนวทางปฏิบัติด้านความปลอดภัยของลูกค้า รวมถึงของผู้ใช้บริการรายนี้",
    "privacyPolicy": "นโยบายคุ้มครองข้อมูลส่วนบุคคล",
    "termsOfDescription": "แบบฟอร์มขอใบกำกับภาษีเต็มรูปแบบนี้ ถูกเปิดใช้งานโดยผู้ใช้บริการบนซอฟต์แวร์ Leceipt ข้อมูลที่ลูกค้ากรอกจะถูกส่งเข้าสู่บัญชีนี้ ซึ่งจะถือว่าผู้ใช้บริการที่เปิดใช้งานแบบฟอร์มเป็นผู้เก็บรวบรวมข้อมูลและนำข้อมูลส่วนบุคคลไปใช้งาน โดยผู้ใช้งานที่เปิดใช้แบบฟอร์มนี้ ต้องทำการแจ้งนโยบายคุ้มครองข้อมูลส่วนบุคคลต่อผู้กรอกแบบฟอร์ม ทาง Leceipt ในฐานะผู้ให้บริการซอฟต์แวร์ จะไม่นำข้อมูลส่วนบุคคลนี้ไปใช้ และจะไม่รับผิดชอบต่อความเป็นส่วนตัวหรือแนวทางปฏิบัติด้านความปลอดภัยของลูกค้า",
    "acknowledge": "รับทราบถึงนโยบายคุ้มครองข้อมูลส่วนบุคคล",
    "warningUser": "ผู้ใช้บริการซอฟต์แวร์ Leceipt ทีได้เปิดใช้งานแบบฟอร์มนี้ ไม่ได้ให้คำชี้แจงสิทธิ์ส่วนบุคคลเกี่ยวกับวิธีการใช้ข้อมูลการตอบกลับของคุณ อย่าให้ข้อมูลส่วนบุคคลหรือข้อมูลที่ละเอียดอ่อน ",
    "acknowledgePrivacy": "ยอมรับ",
    "getStarted": "เริ่มต้นใช้งาน",
    "termOfService": "ข้อตกลงและเงื่อนไขการใช้งาน",
    "backToHomePage": "กลับไปหน้าแรก",
    "latestUpdate": "อัปเดตล่าสุด",
    "refNumber": "เลขที่อ้างอิง",
    "orderAndTaxInvoiceAbb": "ออเดอร์/ใบกำกับภาษีอย่างย่อ",
    "optional": "(ถ้ามี)"
  },
};
