import * as React from "react";
import {
  Dialog,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogContent,
  DialogActions,
  Button,
  makeStyles,
  shorthands,
  Checkbox
} from "@fluentui/react-components";
import { useTranslation } from "react-i18next";
import {
  useId,
  Option,
  Dropdown,
  Text
} from "@fluentui/react-components";


import { useEffect } from "react";
const useStyles = makeStyles({
  dialog: {
    maxWidth: "fit-content",
    minWidth: "40vw",
    width: "90vw",
    '@media (max-width: 780px)': {
      display: "flex",
      width: '90vw',

    },
    '@media (min-width: 1000px)': {
      display: "flex",
      maxWidth: "50vw",

    },
    // '@media (min-width: 1441px)': { 
    // display:"flex",
    // maxWidth:"40vw",

    // },
    ...shorthands.borderRadius("1rem")
  },

  title: {
    paddingBottom: "1rem",
    '@media (max-width: 780px)': {
      fontSize: "1rem"
    }
  },

  dialogContent: {
    maxHeight: "50rem",
    width: "100%",

  }
  ,
  button: {

    '@media (max-width: 700px)': {
      display: "flex",
      width: '300px',
      height: "40px"
    },
  }
  ,
  resolutionButton: {
    '@media (max-width: 780px)': {
      display: "flex",
      width: '100%',
      flexDirection: "row-reverse"
    },
  },
  body: {
    width: "100%"
  }
});

export const DialogStatement = ({
  policyMessage,
  onOpenDialog,
  setOnOpenDialog,
  acceptPolicy,
  setAcceptPolicy,
  languageLabel,
  setLanguageLabel
}) => {
  const styles = useStyles();


  const { t, i18n } = useTranslation();

  const dropdownLanguage = useId("dropdown-default");

  const optionsLanguage = [
    {
      "Abb": "TH",
      "Full": "ภาษาไทย"
    },
    {
      "Abb": "EN",
      "Full": "English"
    }
  ];


  function languageChange(lang) {
    const language = lang.toString().toLocaleLowerCase();

    const newLanguage = language === "th" ? "th" : "en";
    i18n.changeLanguage(newLanguage);

    const upperCaseLanguage = newLanguage.toLocaleUpperCase()

    setLanguageLabel(upperCaseLanguage)
  }

  const adjustPolicyAccept = () => {

    setAcceptPolicy((prevState) => !prevState);
  };

  useEffect(() => {
    if (policyMessage.thaiMessage === "" || policyMessage.thaiMessage.length < 20) {
      setAcceptPolicy(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyMessage])



  return (
    <Dialog open={onOpenDialog} modalType="alert" inertTrapFocus="1.5rem"  >



      {/* <DialogTrigger disableButtonEnhancement>
        <Button>Open dialog</Button>
      </DialogTrigger> */}
      <DialogSurface className={styles.dialog}  >
        <div
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            position: "absolute",
            right: "5%",
            zIndex: "99999",
            backgroundColor: "red"
          }}
        >
          <Dropdown
            aria-labelledby={dropdownLanguage}
            placeholder={languageLabel} // Ensure Abb is used here
            dropdownWidthAuto={true}
            listBox={{ className: styles.listBox }}
            style={{
              minWidth: "50px",
              position: "absolute",
            }}

          >
            {optionsLanguage.map((option) => (
              <Option key={option.Abb} onClick={() => languageChange(option.Abb)}>

                <Text>{option.Full}</Text>

                {/* Show Full text inside the dropdown */}
              </Option>
            ))}
          </Dropdown>
        </div>

        <DialogBody className={styles.body}>
          <DialogTitle className={styles.title}>{t("addContact.privacyPolicy")}</DialogTitle>
          <DialogContent
            className={`${styles.dialogContent} dialogContentCustomScroll`}
            style={{
              overflowY: 'auto',
              scrollbarWidth: 'thin', /* For Firefox */
              scrollbarColor: '#888 #f0f0f0' /* For Firefox */
            }}
          >
            <div style={{ width: "100%" }}>
              {policyMessage.english === true ? (
                <>
                  {i18n.language === "th" && (
                    policyMessage.thaiMessage ? (
                      <div dangerouslySetInnerHTML={{ __html: policyMessage.thaiMessage }} />
                    ) : (
                      <>
                        {t("addContact.warningUser")}

                      </>
                    )
                  )
                  }

                  {i18n.language === "en" && (
                    policyMessage.englishMessage ? (
                      <div dangerouslySetInnerHTML={{ __html: policyMessage.englishMessage }} />
                    ) : (
                      <>
                        {t("addContact.warningUser")}
                      </>
                    )
                  )}
                </>
              ) : policyMessage.thaiMessage ? (
                <div dangerouslySetInnerHTML={{ __html: policyMessage.thaiMessage }} />
              ) : (
                <>
                  {t("addContact.warningUser")}

                </>
              )}
            </div>




            {policyMessage.thaiMessage && <Checkbox label={t("addContact.acknowledge")} onClick={() => adjustPolicyAccept()} />}
          </DialogContent>
          <DialogActions className={styles.resolutionButton}>
            <Button appearance="primary" disableButtonEnhancement onClick={() => setOnOpenDialog(false)} className={styles.button} disabled={acceptPolicy === false}>
              {policyMessage.thaiMessage ? t("addContact.acknowledgePrivacy") : t("addContact.getStarted")}
            </Button>
          </DialogActions>
        </DialogBody>

      </DialogSurface>
    </Dialog>
  );
};