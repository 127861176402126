import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import {
  Stack,
  Shimmer,
  ShimmerElementType,
  MessageBar,
  MessageBarType,
} from "@fluentui/react";
import {
  Field,
  Input,
  useId,
  Label,
  Option,
  Textarea,
  Button,
  Dropdown,
} from "@fluentui/react-components";

import {
  getProvinceList,
  getDistrictList,
  getSubDistrictList,
} from "../providers/AreaData";

import MultiStepForm from "./MultiStepForm";
const stackTokens = { childrenGap: 10 };


const shimmerRowHeight32 = [
  { type: ShimmerElementType.line, height: 32, width: 410 },
];

const shimmerRowHeight37 = [
  { type: ShimmerElementType.line, height: 37, width: 410 },
];

const shimmerRowHeight62 = [
  { type: ShimmerElementType.line, height: 62, width: 410 },
];

const centerItem = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

const AddContacts = ({
  step,
  setStep,
  handleNextStep,
  isStepCompleted,
  universaldata,
  setUniversaldata,
  urlID,
  savedData,
  Loading,
  policyMessage,
  version,
  setOnOpenDialog,
  setAcceptPolicy,
  languageLabel,
  setLanguageLabel
}) => {
  const { t, i18n } = useTranslation();
  const dropdownId = useId("dropdown");
  const outlineId = useId("input-outline");
  const [name, setName] = useState("");
  const [buildingNumber, setBuildingNumber] = useState("");
  const [address, setAddress] = useState("");
  const [streetName, setStreetName] = useState("");
  const [streetPrefix, setStreetPrefix] = useState("")
  const [provincePrefix, setProvincePrefix] = useState("");
  const [province, setProvince] = useState({ key: "", text: "" });
  const [provinceKey, setProvinceKey] = useState(null);
  const [taxNumberType, setTaxNumberType] = useState("");
  const [taxNumberText, setTaxNumberText] = useState("");
  const taxNumberTypeRef = useRef();
  const taxBranchNumberTypeRef = useRef();
  const provinceRef = useRef();
  const districtRef = useRef();
  const subDistrictRef = useRef();
  const countryCode = "TH";
  const [taxBranchNumberType, setTaxBranchNumberType] = useState("");
  const [districtPrefix, setDistrictPrefix] = useState("");
  const [district, setDistrict] = useState({ key: "", text: "" });
  const [districtList, setDistrictList] = useState([]);
  const subDistrictCode = ""
  const [subDistrictPrefix, setSubDistrictPrefix] = useState("");
  const [subDistrict, setSubDistrict] = useState({ key: "", text: "" });
  const [subDistrictList, setSubDistrictList] = useState([]);
  const [postcode, setPostCode] = useState("");
  const [note, setNote] = useState("");
  const [phone, setPhone] = useState("");
  const [taxInvAbbNumber, setTaxInvAbbNumber] = useState("");
  const [email, setEmail] = useState("");
  const [taxId, setTaxId] = useState("");
  const [branchNumber, setBranchNumber] = useState("00000");
  const [showBranchNumber, setShowBranchNumber] = useState(false);
  const [branchText, setBranchText] = useState("สำนักงานใหญ่");
  const [branchTextThai, setBranchTextThai] = useState("สำนักงานใหญ่");
  const [branchTextEnglish, setBranchTextEnglish] = useState("สำนักงานใหญ่");

  const [branchDisplay, setBranchDisplay] = useState([
    { key: "00000", text: "สำนักงานใหญ่" },
    { key: "branchNumber", text: "สาขาเลขที่" },
  ]);

  const BranchoptionsTh = [
    { key: "00000", text: "สำนักงานใหญ่" },
    { key: "branchNumber", text: "สาขาเลขที่" },
  ];
  const BranchoptionsEn = [
    { key: "00000", text: "Head office" },
    { key: "branchNumber", text: "Branch Number" },
  ];
  const [showValidateTaxID, setShowValidateTaxID] = useState(false);
  const [showValidateTaxIDText, setShowValidateTaxIDText] = useState(false);
  const [showValidateBuildingNumber, setShowValidateBuildingNumber] =
    useState(false);
  const [showValidateBranchNumber, setShowValidateBranchNumber] =
    useState(false);
  const [showValidateAddress, setShowValidateAddress] = useState(false);
  const [showValidateProvince, setShowValidateProvince] = useState(false);
  const [showValidateDistrict, setShowValidateDistrict] = useState(false);
  const [showValidateSubDistrict, setShowValidateSubDistrict] = useState(false);
  const [showValidateEmail, setShowValidateEmail] = useState(false);
  const [showMessageBarSuccess, setShowMessageBarSuccess] = useState(false);
  const [showMessageBarError, setShowMessageBarError] = useState(false);
  const [showMessageBarErrorName, setShowMessageBarErrorName] = useState(false);
  const [showMessageBarErrorPostcode, setShowMessageBarPostcode] =
    useState(false);
  const [showMessageBarRepeatTaxId, setShowMessageBarRepeatTaxId] =
    useState(false);
  const [showMessageBarRepeatOTHR, setShowMessageBarRepeatOTHR] =
    useState(false);
  const [showMessageBarSearchError, setShowMessageBarSearchError] =
    useState(false);
  const [showMessageBarSearchNotFound, setShowMessageBarSearchNotFound] =
    useState(false);


  const [provinceList, setProvinceList] = useState([]);

  useEffect(() => {
    setProvinceList(getProvinceList(i18n.language));

  }, [i18n.language]);

  useEffect(() => {
    if (province.key) {
      const updatedText = getProvinceList(i18n.language).find(
        (p) => p.key === province.key
      )?.text;
      setProvince((prev) => ({ ...prev, text: updatedText || prev.text }));
    }
  }, [i18n.language, province.key]);

  useEffect(() => {
    const updatedDistrictList = getDistrictList(province.key, i18n.language);
    console.log("Updated District List:", updatedDistrictList);
    setDistrictList(updatedDistrictList);

  }, [i18n.language, province]);



  useEffect(() => {
    if (district.key) {
      const updatedText = getDistrictList(province.key, i18n.language).find(
        (d) => d.key === district.key
      )?.text;

      console.log("Updated District Text:", updatedText);

      setDistrict((prev) => ({ ...prev, text: updatedText || prev.text }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, district.key]);

  useEffect(() => {
    const updatedSubDistrictList = getSubDistrictList(district.key, i18n.language);
    console.log("Updated subDistrict List:", updatedSubDistrictList);
    setSubDistrictList(updatedSubDistrictList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, province]);



  useEffect(() => {
    if (subDistrict.key) {
      const updatedText = getSubDistrictList(district.key, i18n.language).find(
        (sd) => sd.key === subDistrict.key)?.text;

      console.log("sub district text", updatedText);

      setSubDistrict((prev) => ({ ...prev, text: updatedText || prev.text }))

    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, subDistrict.key])

  useEffect(() => {
    if (provinceKey !== null) {
      setAreaPrefix(provinceKey)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provinceKey, i18n.language])




  const isLoadComplete = true

  const [TypeErrMsg, setTypeErrMsg] = useState("");
  const [branchErrMsg, setBranchErrMsg] = useState("");
  const [provinceErrMsg, setProvinceErrMsg] = useState("");
  const [districtErrMsg, setDistrictErrMsg] = useState("");
  const [subDistrictErrMsg, setSubDistrictErrMsg] = useState("");
  const DropdownErrMsg = t("addContact.dropdownErrMsg");
  const [branchBlankDisplay, setBranchBlankDisplay] = useState({
    key: "",
    text: "",
  });
  const history = useHistory();
  const location = useLocation();
  const databack = location.state;

  const [taxTypeText, setTaxTypeText] = useState("");


  useEffect(() => {
    if (taxNumberType) {
      const updatedText = getDocumentTypeText(taxNumberType, i18n.language);
      setTaxTypeText(updatedText || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, taxNumberType]);



  useEffect(() => {
    if (taxNumberType) {
      const updatedText = getDocumentTypeText(taxNumberType, i18n.language);
      setTaxTypeText(updatedText || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n.language, taxNumberType]);


  const getDocumentTypeText = (selectedValue, languageCheck) => {
    if (languageCheck === "th") {
      const foundOption = documentOptionswithTextTh.find(
        (documentoptions) => documentoptions.key === selectedValue
      );

      return foundOption ? foundOption.text : taxNumberText;
    } else {
      const foundOption = documentOptionswithTextEn.find(
        (documentoptions) => documentoptions.key === selectedValue
      );
      return foundOption ? foundOption.text : taxNumberText;
    }
  };


  const getBranchTypeText = (selectedValue) => {
    if (i18n.language === "th") {
      const foundOption = BranchoptionsTh.find(
        (documentoptions) => documentoptions.key === selectedValue
      );

      return foundOption ? foundOption.text : null;
    } else {
      const foundOption = BranchoptionsEn.find(
        (documentoptions) => documentoptions.key === selectedValue
      );
      return foundOption ? foundOption.text : null;
    }
  };


  useEffect(() => {

    if (universaldata === true) {
      setTaxNumberType(databack?.taxNumberType);


      const selectedTaxIdOption = options.find(
        (option) => option.key === databack.taxNumberType
      );
      handleonClick(selectedTaxIdOption, i18n.language);

      setTaxId(databack.taxID);

      // handleBranch(brachOption.key, language);
      if (databack.branchNumber === "00000") {
        setTaxBranchNumberType("00000");
        handleBranch("00000", i18n.language);
      } else {
        setTaxBranchNumberType("branchNumber");
        handleBranch(databack.branchNumber, i18n.language);
      }

      setName(databack.name);
      setBuildingNumber(databack.buildingNo);
      setAddress(databack.address);
      setStreetName(databack.street);
      setPostCode(databack.postcode);
      setProvincePrefix(databack.provincePrefix);
      setProvince(databack.province);
      setProvinceKey(databack.province.key);
      setDistrict(databack.district)
      setSubDistrictPrefix(databack.subDistrictPrefix);
      setSubDistrict(databack.subdistrict);
      setDistrictPrefix(databack.districtPrefix);
      setEmail(databack.email);
      setPhone(databack.tel);
      setNote(databack.note);
      setTaxInvAbbNumber(databack.taxInvAbbNumber)
    }

    setAreaPrefix(provinceKey)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    let isCorrect = true;
    if (!taxNumberType) {
      isCorrect = false;
      taxNumberTypeRef.current.focus();
      taxNumberTypeRef.current.blur();
    }

    if (taxNumberType === "TXID" && taxBranchNumberType === "") {
      isCorrect = false;
      taxBranchNumberTypeRef.current?.focus();
      taxBranchNumberTypeRef.current?.blur();
    }

    if (province.key === "") {
      isCorrect = false;

      provinceRef.current?.focus();
      provinceRef.current?.blur();
    }
    if (district.key === "") {
      isCorrect = false;

      districtRef.current?.focus();
      districtRef.current?.blur();
    }

    if (subDistrict.key === "") {
      isCorrect = false;

      subDistrictRef.current?.focus();
      subDistrictRef.current?.blur();
    }

    if (
      province.key === undefined ||
      province.key === null ||
      province.key === ""
    ) {
      isCorrect = false;
    }

    if (
      district.key === undefined ||
      district.key === null ||
      district.key === ""
    ) {
      isCorrect = false;
    }

    if (
      subDistrict.key === undefined ||
      subDistrict.key === null ||
      subDistrict.key === ""
    ) {
      isCorrect = false;
    }

    if (
      taxNumberType === undefined ||
      taxNumberType === null ||
      taxNumberType === ""
    ) {
      isCorrect = false;
    }

    let _branchNumber = branchNumber;

    if (taxNumberType === "TXID") {
      if (taxId.length !== 13) {
        isCorrect = false;
      }

      if (isNaN(taxId)) {
        isCorrect = false;
      }

      if (isNaN(branchNumber)) {
        if (
          branchText.toLowerCase() === "head office" ||
          branchText === "สำนักงานใหญ่"
        ) {
          _branchNumber = "00000";
        } else {
          isCorrect = false;
        }
      } else {
        if (branchNumber.length !== 5) {
          if (
            branchText.toLowerCase() === "head office" ||
            branchText === "สำนักงานใหญ่"
          ) {
            _branchNumber = "00000";
          } else {
            isCorrect = false;
          }
        }
      }
    } else if (taxNumberType === "NIDN") {
      if (taxId.length !== 13) {
        isCorrect = false;
      }

      if (isNaN(taxId)) {
        isCorrect = false;
      }
    } else if (taxNumberType === "CCPT") {
      if (taxId.length === 0) {
        isCorrect = false;
      }
    }

    if (taxId === "0000000000000" || Number(taxId) === 0) {
      isCorrect = false;
    }

    if (postcode) {
      if (isNaN(postcode)) {
        isCorrect = false;
      }

      if (postcode.length !== 5) {
        isCorrect = false;
      }
    }

    if (name.length > 140) {
      isCorrect = false;
    }

    if (buildingNumber.length > 16) {
      isCorrect = false;
    }

    if (address.length > 256) {
      isCorrect = false;
    }

    if (streetName.length > 70) {
      isCorrect = false;
    }

    if (postcode.length > 16) {
      isCorrect = false;
    }

    if (taxId.length > 35) {
      isCorrect = false;
    }

    if (_branchNumber.length > 5) {
      isCorrect = false;
    }

    if (phone.length > 35) {
      isCorrect = false;
    }

    if (email.length > 256) {
      isCorrect = false;
    }

    if (note.length > 500) {
      isCorrect = false;
    }

    console.log("i18n.language", i18n.language);

    const formData = {
      taxNumberType: taxNumberType,
      taxID: taxId,
      branch: branchText,
      branchTextThai,
      branchTextEnglish,
      branchNumber: branchNumber,
      taxBranchNumberType: taxBranchNumberType,
      name,
      buildingNo: buildingNumber,
      address,
      streetPrefix: streetPrefix,
      street: streetName,
      provincePrefix: provincePrefix,
      province: province,
      provinceKey: provinceKey,
      districtPrefix,
      district: district,
      subdistrictPrefix: subDistrictPrefix,
      subdistrict: subDistrict,
      subDistrictCode: subDistrictCode,
      postcode,
      email,
      tel: phone,
      note,
      taxInvAbbNumber
    };


    // Redirect to PreviewContacts page with form data
    if (isCorrect) {
      history.push({
        pathname: `/summary-information/${urlID}`,
        state: { formData },
      });
      setUniversaldata(false);
      handleNextStep();
    }
  };

  const handleTaxType = (selectedOption) => {
    // console.log("Change tax type:", selectedOption.key);

    setTaxNumberType(selectedOption.key);
    setTaxNumberText(selectedOption.text);

    // if (selectedOption.key === "TXID") {
    //   setBranchNumber("00000");
    //   if (currentLanguage === "en") {
    //     setBranchText("Head office");
    //   } else {
    //     setBranchText("สำนักงานใหญ่");
    //   }
    // } else {
    //   setBranchText("");
    //   setBranchNumber("");
    // }


    if (selectedOption.key === "TXID") {
      setBranchNumber("00000");
      setBranchTextEnglish("Head office");
      setBranchTextThai("สำนักงานใหญ่")
    } else {
      setBranchText("");
      setBranchNumber("");
    }



    if (selectedOption.key === "OTHR") {
      setTaxId("N/A");
    } else {
      setTaxId("");
    }
  };

  useEffect(() => {

    if (i18n.language === "en") {
      setBranchDisplay([
        { key: "00000", text: "Head office" },
        { key: "branchNumber", text: "Branch" },
      ]);
    } else if (i18n.language === "th") {
      setBranchDisplay([
        { key: "00000", text: "สำนักงานใหญ่" },
        { key: "branchNumber", text: "สาขาเลขที่" },
      ]);
    }

  }, [i18n.language])

  const handleBranch = (branchNum, lang) => {
    if (lang === "en") {
      setBranchDisplay([
        { key: "00000", text: "Head office" },
        { key: "branchNumber", text: "Branch" },
      ]);
    } else if (lang === "th") {
      setBranchDisplay([
        { key: "00000", text: "สำนักงานใหญ่" },
        { key: "branchNumber", text: "สาขาเลขที่" },
      ]);
    }

    if (branchNum === "00000") {
      setShowBranchNumber(false);
      // setBranch("00000");
      setBranchNumber("00000");

      if (lang === "en") {
        setBranchText("Head office");
      } else {
        setBranchText("สำนักงานใหญ่");
      }
    } else {
      if (branchNum !== "") {
        // setBranch("branchNumber");
        if (branchNum === "branchNumber") {
          setBranchNumber("00000");

          if (lang === "en") {
            setBranchText("Branch 00000");
          } else {
            setBranchText("สาขา 00000");
          }
        } else {
          setBranchNumber(branchNum);

          if (lang === "en") {
            setBranchText("Branch " + branchNum);
          } else {
            setBranchText("สาขา " + branchNum);
          }
        }
        setShowBranchNumber(true);
      }
    }
  };

  const getErrorMessage = (value) => {
    return value && value !== "0" ? "" : t("addContact.getErrorMessage");
  };

  const getTaxIdErrorMessage = (value) => {
    if (!value) return t("addContact.getErrorMessage");
    if (taxNumberType === "TXID" || taxNumberType === "NIDN") {
      if (isNaN(value)) return t("addContact.getBranchNumberErrorMessage1");
      if (value.length !== 13) return t("addContact.taxIdError");
    }
    return "";
  };

  const getBranchNumberErrorMessage = (value) => {
    if (!value) return t("addContact.pleaseSelectBranch");
    if (isNaN(value)) return t("addContact.getBranchNumberErrorMessage1");
    if (value.length !== 5) return t("addContact.getBranchNumberErrorMessage2");
    return "";
  };

  const getEmailValidateMessage = (email) => {
    if (!email) {
      return t("addContact.pleaseEnterYourEmail");
    }
    if (!isValidEmail(email)) {
      return t("addContact.pleaseCheckYourEmail");
    }
    return "";
  };

  // Function to check if email is valid
  const isValidEmail = (email) => {
    // Regular expression for validating email format
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const getTypeNull = (name) => {
    if (!name) {
      return t("addContact.pleaseSelectTaxPayerType");
    }

    return "";
  };

  const getNameNull = (name) => {
    if (!name) {
      return t("addContact.pleaseEnterYourName");
    }

    return "";
  };

  const getฺBuildingNumberNull = (name) => {
    if (!name) {
      return t("addContact.pleaseEnterTheNumber");
    }

    return "";
  };

  const getฺAddressNull = (name) => {
    if (!name) {
      return t("addContact.pleaseEnterTheAddress");
    }

    return "";
  };

  const getฺProvinceNull = (name) => {
    if (!name) {
      return t("addContact.pleaseSelectaProvince");
    }

    return "";
  };

  const getฺDistrictNull = (name) => {
    if (!name) {
      return t("addContact.pleaseSelectaDistrict");
    }

    return "";
  };

  const getฺsubDistrictNull = (name) => {
    if (!name) {
      return t("addContact.pleaseSelectaSubdistrict");
    }

    return "";
  };

  const getPostCodeErrorMessage = (value) => {
    if (countryCode === "TH") {
      if (isNaN(value)) return t("addContact.getPostCodeErrorMessage1");
      if (!value || value.length !== 5)
        return t("addContact.getPostCodeErrorMessage2");
    }

    if (countryCode === "EN") {
      if (isNaN(value)) return "Please fill 16 numeric characters";
    }
  };


  const [typeContact, setTypeContact] = useState({ key: "", text: "" });

  const documentOptionswithTextTh = [
    { key: "TXID", text: "เลขประจำตัวผู้เสียภาษี (นิติบุคคล)" },
    { key: "NIDN", text: "เลขประจำตัวประชาชน (บุคคลธรรมดา)" },
    { key: "CCPT", text: "เลขที่หนังสือเดินทาง" },
  ];

  const documentOptionswithTextEn = [
    { key: "TXID", text: "Tax ID (Juristic Person) " },
    { key: "NIDN", text: "ID Number (Ordinary Person)" },
    { key: "CCPT", text: "Passport Number" },
  ];

  const options = [
    { key: "TXID", text: t("addContact.txid") },
    { key: "NIDN", text: t("addContact.nidn") },
    { key: "CCPT", text: t("addContact.ccpt") },
  ];

  const handleonClick = (newValue) => {
    handleTaxType(newValue);
  };


  const setPostCodelength = (e) => {
    // The controlled input pattern can be used for other purposes besides validation,
    // but validation is a useful example
    if (e.target.value.length <= 16 && i18n.language === "en") {
      setPostCode(e.target.value);
    }

    if (e.target.value.length <= 5 && i18n.language === "th") {
      setPostCode(e.target.value);
    }
  };
  const handlesetbuildingNumber = (e) => {
    setShowValidateBranchNumber(false);
    setBuildingNumber(e.target.value);
  };

  const setNotelength = (e) => {
    if (e.target.value.length <= 500) {
      setNote(e.target.value);
    }
  };

  const setTaxIdlength = (e) => {
    if (e.length <= 13) {
      setTaxId(e);
    }
  };

  const setBranchlength = (e) => {
    if (e.length <= 5) {
      setBranchNumber(e);
    }
  };

  const setPhonelength = (e) => {
    if (e.length <= 10) {
      setPhone(e);
    }
  };

  const setTaxInvAbblength = (e) => {
    if (e.length <= 35) {
      setTaxInvAbbNumber(e);
    }
  };


  const setAreaPrefix = (_provinceCode) => {
    if (_provinceCode === "10") {
      if (i18n.language === "th") {
        setSubDistrictPrefix("แขวง");
        setDistrictPrefix("เขต");
        setProvincePrefix("");
        setStreetPrefix('ถ.');
      } else {
        setSubDistrictPrefix("");
        setDistrictPrefix("");
        setProvincePrefix("");
      }
    } else {
      if (i18n.language === "en") {
        setSubDistrictPrefix("");
        setDistrictPrefix("");
        setProvincePrefix("");
      } else {
        setSubDistrictPrefix("ต.");
        setDistrictPrefix("อ.");
        setProvincePrefix("จ.");
        setStreetPrefix('ถ.');
      }
    }
  };



  return (
    <div className="responsive-element" style={{ position: "relative" }}>
      {/* <DialogStatement  /> */}
      {showMessageBarSuccess && (
        <MessageBar
          messageBarType={MessageBarType.success}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSuccess(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("addContact.updateSuccess")}
        </MessageBar>
      )}
      {showMessageBarError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("addContact.updateFail")}
        </MessageBar>
      )}
      {showMessageBarRepeatTaxId && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarRepeatTaxId(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("addContact.alreadyHaveTaxId")}
        </MessageBar>
      )}
      {showMessageBarRepeatOTHR && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarRepeatOTHR(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("addContact.alreadyHaveContact")}
        </MessageBar>
      )}
      {showMessageBarSearchError && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSearchError(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("company.searchFail")}
        </MessageBar>
      )}
      {showMessageBarSearchNotFound && (
        <MessageBar
          messageBarType={MessageBarType.error}
          isMultiline={false}
          onDismiss={() => setShowMessageBarSearchNotFound(false)}
          dismissButtonAriaLabel="Close"
        >
          {t("company.searchNotFound")}
        </MessageBar>
      )}

      <form onSubmit={handleSubmit} >
        <div className="header-part">
          {step === 1 && (
            <MultiStepForm
              step={step}
              setStep={setStep}
              isStepCompleted={isStepCompleted}
              savedData={savedData}
              Loading={Loading}
              languageLabel={languageLabel}
              setLanguageLabel={setLanguageLabel}

            />
          )}


        </div>
        {isLoadComplete ? (
          <div className="card-style">
            <div className="field-long">
              <Label
                style={{
                  fontSize: "1.4rem",
                  alignSelf: "center",
                  fontWeight: "bold",

                }}
              >
                {t("addContact.enterClient")}
              </Label>
            </div>

            <div className="company-information">

              <div className="taxNumberType">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        htmlFor={outlineId}
                        style={{
                          fontSize: "1.2rem",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "5px",
                        }}
                      >
                        {t("addContact.taxNumberType")}
                      </Label>
                    }
                    required={true}
                    // label={t("addContact.taxId")}
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="large"
                    validationMessage={
                      showValidateTaxID ? getTypeNull(options.key) : ""
                    }
                  >
                    <Dropdown
                      key={i18n.language}
                      aria-labelledby={`${dropdownId}-outline`}
                      placeholder={t("addContact.pleaseSelect")}
                      onOptionSelect={(_, data) => {
                        handleonClick(data.optionValue, i18n.language);
                        setTypeContact(data.optionValue);
                        setShowValidateTaxID(false);

                        // ตั้งค่าเป็น text โดยตรง
                        setTaxTypeText(data.optionValue.text);
                      }}
                      appearance="outline"
                      size="large"
                      style={{ minWidth: "25%" }}
                      required={true}
                      value={taxTypeText} // ใช้ text ของ dropdown
                      ref={taxNumberTypeRef}
                      onBlur={() => {
                        if (typeContact.key === "") {
                          setTypeErrMsg(DropdownErrMsg);
                          setShowValidateTaxID(true);
                        } else {
                          setTypeErrMsg("");
                        }
                      }}
                      errorMessage={TypeErrMsg}
                    >
                      {options.map((option) => (
                        <Option
                          key={option.key}
                          value={option} // ต้องตรงกับการใช้ใน onOptionSelect
                          text={option.text}
                        >
                          {option.text}
                        </Option>
                      ))}
                    </Dropdown>

                  </Field>
                </div>
              </div>

              {taxNumberType !== "" && (
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        htmlFor={outlineId}
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "3px",
                        }}
                      >
                        {taxNumberType === "NIDN"
                          ? t("addContact.ninText")
                          : taxNumberType === "TXID"
                            ? t("addContact.tinText")
                            : taxNumberType === "OTHR"
                              ? t("addContact.passportText")
                              : t("addContact.passportText")}
                      </Label>
                    }
                    required
                    // label={t("addContact.taxId")}
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="large"
                    validationMessage={
                      showValidateTaxIDText ? getTaxIdErrorMessage(taxId) : ""
                    }
                  >
                    <Input
                      value={taxId}
                      onChange={(e) => {
                        setShowValidateTaxIDText(false);
                        if (
                          taxNumberType === "TXID" ||
                          taxNumberType === "NIDN"
                        ) {
                          if (!isNaN(e.target.value)) {
                            setTaxIdlength(e.target.value);
                          }
                        } else {
                          setTaxIdlength(e.target.value);
                        }
                      }}
                      validateOnLoad={false}
                      validateOnFocusOut
                      onBlur={(e) => {
                        setShowValidateTaxIDText(true);
                      }}
                    />
                  </Field>
                </div>
              )}

              {taxNumberType === "" && (
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        htmlFor={outlineId}
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "3px",
                          marginTop: "3px",
                        }}
                      >
                        {t("addContact.firstNameLastName")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="large"
                    required
                    validationMessage={
                      showMessageBarErrorName ? getNameNull(name) : ""
                    }
                  >
                    {" "}
                    <Input
                      appearance="outline"
                      id={outlineId}
                      size="large"
                      name="name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      onGetErrorMessage={getErrorMessage}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                    />
                  </Field>
                </div>
              )}

              {taxNumberType === "TXID" && (
                <>
                  <div className="branchDisplay">
                    <div className="field-long">
                      <Field
                        label={
                          <Label
                            htmlFor={outlineId}
                            style={{
                              fontSize: "1.2rem",

                              fontWeight: "bold",
                              alignSelf: "left",
                              marginBottom: "3px",
                            }}
                          >
                            {t("addContact.branchDisplay")}
                          </Label>
                        }
                        style={{
                          textAlign: "left",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                        size="large"

                        validationMessage={
                          showValidateBranchNumber
                            ? getBranchNumberErrorMessage(branchDisplay.key)
                            : ""
                        }
                        required
                      >
                        <Dropdown
                          key={i18n.language}
                          aria-labelledby={`${dropdownId}-outline`}
                          size="large"
                          style={{ minWidth: "25%" }}
                          placeholder={t("addContact.pleaseSelect")}
                          appearance="outline"
                          required
                          value={getBranchTypeText(taxBranchNumberType)}
                          ref={taxBranchNumberTypeRef}
                          onBlur={() => {
                            if (branchBlankDisplay.key === "") {
                              setBranchErrMsg(DropdownErrMsg);
                              setShowValidateBranchNumber(true);
                            } else {
                              setBranchErrMsg("");
                            }
                          }}
                          errorMessage={branchErrMsg}
                        >
                          {branchDisplay.map((option) => (
                            <Option
                              key={option.key}
                              onClick={() => {
                                setBranchBlankDisplay(option);
                                setTaxBranchNumberType(option.key);
                                setShowValidateBranchNumber(false);
                                handleBranch(option.key, i18n.language);
                              }}
                              text={option.text}
                            >
                              {option.text}
                            </Option>
                          ))}
                        </Dropdown>
                      </Field>
                    </div>
                  </div>

                  {showBranchNumber && (
                    <div className="branchNumber">
                      <div className="field-long">
                        <Field
                          label={
                            <Label
                              htmlFor={outlineId}
                              style={{
                                fontSize: "1.2rem",

                                fontWeight: "bold",
                                alignSelf: "left",
                                marginBottom: "3px",
                              }}
                            >
                              {t("addContact.branchNumber")}
                            </Label>
                          }
                          required
                          style={{
                            textAlign: "left",
                            display: "flex",
                            flexDirection: "column",
                            width: "100%",
                          }}
                          size="large"
                          validationMessage={
                            showValidateBranchNumber
                              ? getBranchNumberErrorMessage(branchNumber)
                              : ""
                          }
                        >
                          <Input
                            value={branchNumber}
                            onChange={(e) => {
                              setShowValidateBranchNumber(false);
                              if (!isNaN(e.target.value)) {
                                setBranchlength(e.target.value);

                                if (i18n.language === "en") {
                                  setBranchText("Branch " + e.target.value);
                                } else {
                                  setBranchText("สาขา " + e.target.value);
                                }
                              }
                            }}
                          />
                        </Field>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                height: "80px",
                columnGap: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  height: "80px",
                  columnGap: "10px",
                }}
              >
                {(taxNumberType === "TXID" ||
                  taxNumberType === "OTHR" ||
                  taxNumberType === "CCPT") && (
                    <div className="field-long">
                      <Field
                        label={
                          <Label
                            htmlFor={outlineId}
                            style={{
                              fontSize: "1.2rem",

                              fontWeight: "bold",
                              alignSelf: "left",
                              marginBottom: "3px",
                            }}
                          >
                            {taxNumberType === "NIDN"
                              ? t("addContact.companyName")
                              : taxNumberType === "TXID"
                                ? t("addContact.companyName")
                                : taxNumberType === "OTHR"
                                  ? t("addContact.onlyName")
                                  : t("addContact.onlyName")}
                          </Label>
                        }
                        style={{
                          textAlign: "left",
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                        size="large"
                        required
                        validationMessage={
                          showMessageBarErrorName ? getNameNull(name) : ""
                        }
                      >
                        <Input
                          appearance="outline"
                          id={outlineId}
                          size="large"
                          name="name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          onGetErrorMessage={getErrorMessage}
                          validateOnLoad={false}
                          validateOnFocusOut
                          required
                          onBlur={(e) => {
                            setShowMessageBarErrorName(true);
                          }}
                        />
                      </Field>
                    </div>
                  )}
                {taxNumberType === "NIDN" && (
                  <div className="field-long">
                    <Field
                      label={
                        <Label
                          htmlFor={outlineId}
                          style={{
                            fontSize: "1.2rem",

                            fontWeight: "bold",
                            alignSelf: "left",
                            marginBottom: "3px",
                            marginTop: "5px",
                          }}
                        >
                          {t("addContact.firstNameLastName")}
                        </Label>
                      }
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      size="large"
                      required
                      validationMessage={
                        showMessageBarErrorName ? getNameNull(name) : ""
                      }
                    >
                      {" "}
                      <Input
                        appearance="outline"
                        id={outlineId}
                        size="large"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        onGetErrorMessage={getErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                      />
                    </Field>
                  </div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="card-style">
            <Label size="large">{t("addContact.companyInfo")}</Label>

            <Stack
              horizontal
              tokens={stackTokens}
              styles={{ root: { marginTop: 38.4 } }}
            >
              <Shimmer shimmerElements={shimmerRowHeight32} width="170px" />
              <Shimmer shimmerElements={shimmerRowHeight32} width="120px" />
              <Shimmer shimmerElements={shimmerRowHeight32} width="120px" />
              <Shimmer shimmerElements={shimmerRowHeight32} width="100px" />
            </Stack>
            <Stack styles={{ root: { marginTop: 28.4 } }}>
              <Shimmer shimmerElements={shimmerRowHeight32} width="617px" />
            </Stack>
          </div>
        )}

        <br />
        {isLoadComplete ? (
          <div className="card-style">
            <div className="field-long">
              <Label
                style={{
                  fontSize: "1.4rem",

                  fontWeight: "bold",
                  alignSelf: "center",
                }}
              >
                {t("addContact.addressHeader")}
              </Label>
            </div>
            <Stack
              horizontal
              tokens={stackTokens}
              styles={{ root: { marginTop: 10 } }}
            >
            </Stack>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                rowGap: "10px",
              }}
            >
              <div className="build-number-and-address">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        htmlFor={outlineId}
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "3px",
                        }}
                      >
                        {t("addContact.buildingNumber")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="large"
                    validationMessage={
                      showValidateBuildingNumber
                        ? getฺBuildingNumberNull(buildingNumber)
                        : ""
                    }
                    required
                  >
                    {" "}
                    <Input
                      appearance="outline"
                      id={outlineId}
                      value={buildingNumber}
                      min-width="25%"
                      size="large"
                      name="buildingNumber"
                      onChange={handlesetbuildingNumber}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                      onBlur={(e) => {
                        setShowValidateBuildingNumber(true);
                      }}
                    />
                  </Field>
                </div>

                <div className="field-long">
                  <Field
                    label={
                      <Label
                        htmlFor={outlineId}
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "3px",
                        }}
                      >
                        {t("addContact.address")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="large"
                    validationMessage={
                      showValidateAddress ? getฺAddressNull(address) : ""
                    }
                    required
                  >
                    <Input
                      appearance="outline"
                      size="large"
                      id={outlineId}
                      name="address"
                      label={t("addContact.address")}
                      value={address}
                      onChange={(e) => {
                        setShowValidateAddress(false);
                        setAddress(e.target.value);
                      }}
                      validateOnLoad={false}
                      validateOnFocusOut
                      required
                      onBlur={(e) => {
                        setShowValidateAddress(true);
                      }}
                    />
                  </Field>
                </div>
              </div>

              <div className="street">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        htmlFor={outlineId}
                        style={{
                          fontSize: "1.2rem",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "3px",
                        }}
                      >
                        {t("addContact.streetName")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="large"
                  >
                    <Input
                      appearance="outline"
                      id={outlineId}
                      size="large"
                      name="streetName"
                      label={t("addContact.streetName")}
                      value={streetName}
                      onChange={(e) => setStreetName(e.target.value)}
                    />
                  </Field>
                </div>
              </div>

              <div className="province">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        style={{
                          fontSize: "1.2rem",

                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "3px",
                        }}
                      >
                        {t("addContact.province")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="large"
                    validationMessage={
                      showValidateProvince
                        ? getฺProvinceNull(provinceList.key)
                        : ""
                    }
                    required
                  >
                    <Dropdown
                      key={i18n.language}
                      aria-labelledby={`${dropdownId}-outline`}
                      placeholder={t("addContact.pleaseSelect")}
                      onOptionSelect={(_, data) => {
                        console.log("data", data);
                        setProvince(data.optionValue);
                        setAreaPrefix(data.optionValue.key);
                        setProvinceKey(data.optionValue.key);
                        setDistrict({ key: "", text: "" });
                        setDistrictList(
                          getDistrictList(data.optionValue.key, i18n.language)
                        );
                        setSubDistrict({ key: "", text: "" });
                        setProvinceErrMsg("");
                        setDistrictErrMsg("");
                        setSubDistrictErrMsg("");
                        setShowValidateProvince(false);
                      }}
                      appearance="outline"
                      name="province"
                      required
                      size="large"
                      style={{ minWidth: "25%" }}
                      label={t("addContact.province")}
                      value={province.text}
                      ref={provinceRef}
                      onBlur={() => {
                        if (province.key === "") {
                          setProvinceErrMsg(DropdownErrMsg);
                          setShowValidateProvince(true);
                        } else {
                          setProvinceErrMsg("");
                        }
                      }}
                      errorMessage={provinceErrMsg}
                    >
                      {provinceList.map((option) => (
                        <Option value={option} key={option.key}>
                          {option.text}
                        </Option>
                      ))}
                    </Dropdown>

                  </Field>
                </div>
              </div>

              <div className="district">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        id={`${dropdownId}-outline`}
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "3px",
                        }}
                      >
                        {t("addContact.district")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="large"
                    validationMessage={
                      showValidateDistrict
                        ? getฺDistrictNull(districtList.key)
                        : ""
                    }
                    required
                  >
                    <Dropdown
                      key={i18n.language}
                      placeholder={t("addContact.pleaseSelect")}
                      onOptionSelect={(_, data) => {
                        console.log("data", data);

                        setDistrict(data.optionValue);
                        setSubDistrict({ key: "", text: "" });
                        setSubDistrictList(
                          getSubDistrictList(data.optionValue.key, i18n.language)
                        );
                        setDistrictErrMsg("");
                        setSubDistrictErrMsg("");
                        setShowValidateDistrict(false);
                      }}
                      appearance="outline"
                      required
                      style={{ minWidth: "25%" }}
                      size="large"
                      label={t("addContact.district")}
                      value={district.text}
                      errorMessage={districtErrMsg}
                    >
                      {districtList.map((option) => (
                        <Option value={option} key={option.key}>
                          {option.text}
                        </Option>
                      ))}
                    </Dropdown>

                  </Field>{" "}
                </div>
              </div>

              <div className="subdistrict">
                <div className="field-long">
                  <Field
                    label={
                      <Label
                        style={{
                          fontSize: "1.2rem",
                          fontWeight: "bold",
                          alignSelf: "left",
                          marginBottom: "3px",
                        }}
                      >
                        {t("addContact.subDistrict")}
                      </Label>
                    }
                    style={{
                      textAlign: "left",
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                    size="large"
                    validationMessage={
                      showValidateSubDistrict
                        ? getฺsubDistrictNull(subDistrictList.key)
                        : ""
                    }
                    required
                  >
                    <Dropdown
                      key={i18n.language}
                      aria-labelledby={`${dropdownId}-outline`}
                      placeholder={t("addContact.pleaseSelect")}
                      onOptionSelect={(_, data) => {
                        setSubDistrict(data.optionValue);
                        setSubDistrictErrMsg("");
                        setShowValidateSubDistrict(false);

                      }}
                      appearance="outline"
                      style={{ minWidth: "25%" }}
                      required
                      size="large"
                      label={t("addContact.subDistrict")}
                      value={subDistrict.text}
                      ref={subDistrictRef}
                      onBlur={(e) => {
                        if (district.key !== "" && subDistrict.key === "") {
                          setSubDistrictErrMsg(DropdownErrMsg);
                          setShowValidateSubDistrict(true);
                        } else {
                          setSubDistrictErrMsg("");
                        }
                      }}
                      errorMessage={subDistrictErrMsg}
                    >
                      {subDistrictList.map((option) => (
                        <Option
                          key={option.key}
                          value={option}
                          text={option.text}
                        >
                          {option.text}
                        </Option>
                      ))}
                      {/* {subDistrictList.map((option) => (
                        <Option
                          key={option.key}
                          onClick={(e) => {
                            setSubDistrict(option);
                            setSubDistrictErrMsg("");
                            setShowValidateSubDistrict(false);
                          }}
                          text={option.text}
                        >
                          {option.text}
                        </Option>
                      ))} */}
                    </Dropdown>
                  </Field>{" "}
                </div>
              </div>
              <div className="build-number-and-address">
                <div className="postCode">
                  <div className="field-long">
                    <Field
                      label={
                        <Label
                          htmlFor={outlineId}
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            alignSelf: "left",
                          }}
                        >
                          {t("addContact.postcode")}
                        </Label>
                      }
                      style={{
                        textAlign: "left",
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                      size="large"
                      validationMessage={
                        showMessageBarErrorPostcode
                          ? getPostCodeErrorMessage(postcode)
                          : ""
                      }
                      required
                    >
                      <Input
                        appearance="outline"
                        id={outlineId}
                        name="postcode"
                        type="number"
                        size="large"
                        min-width="25%"
                        label={t("addContact.postcode")}
                        value={postcode}
                        onChange={setPostCodelength}
                        onGetErrorMessage={getPostCodeErrorMessage}
                        validateOnLoad={false}
                        validateOnFocusOut
                        required
                        onBlur={(e) => {
                          setShowMessageBarPostcode(true);
                        }}
                      />
                    </Field>{" "}
                  </div>
                </div>

              </div>
            </div>
          </div>
        ) : (
          <div className="card-style">
            <strong>{t("addContact.addressHeader")}</strong>
            <Stack
              horizontal
              tokens={stackTokens}
              styles={{ root: { marginTop: 10 } }}
            >
              <Shimmer shimmerElements={shimmerRowHeight37} width="350px" />
            </Stack>
            <Stack
              horizontal
              tokens={stackTokens}
              styles={{ root: { marginTop: 28.4 } }}
            >
              <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
              <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
            </Stack>
            <Stack
              horizontal
              tokens={stackTokens}
              styles={{ root: { marginTop: 28.4 } }}
            >
              <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
              <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
            </Stack>
            <Stack
              horizontal
              tokens={stackTokens}
              styles={{ root: { marginTop: 28.4 } }}
            >
              <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
              <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
            </Stack>
            <Stack
              horizontal
              tokens={stackTokens}
              styles={{ root: { marginTop: 28.4 } }}
            >
              <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
              <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
            </Stack>
            <Stack
              horizontal
              tokens={stackTokens}
              styles={{ root: { marginTop: 28.4 } }}
            >
              <Shimmer shimmerElements={shimmerRowHeight32} width="150px" />
              <Shimmer shimmerElements={shimmerRowHeight32} width="450px" />
            </Stack>
            <Shimmer
              styles={{ root: { marginTop: 28.4 } }}
              shimmerElements={shimmerRowHeight32}
              width="150px"
            />
          </div>
        )}

        <br />

        {isLoadComplete ? (
          <div className="card-style">
            <div className="field-long">
              {" "}
              <Label
                style={{
                  fontSize: "1.4rem",

                  fontWeight: "bold",
                  alignSelf: "center",
                }}
              >
                {t("addContact.contactHeader")}
              </Label>
            </div>

            <div className="contact">
              <div className="field-long">
                <Field
                  label={
                    <Label
                      htmlFor={outlineId}
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        alignSelf: "left",
                        marginBottom: "3px",
                      }}
                    >
                      {t("addContact.email")}
                    </Label>
                  }
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                  size="large"
                  required
                  validationMessage={
                    showValidateEmail ? getEmailValidateMessage(email) : ""
                  }
                >
                  <Input
                    appearance="outline"
                    id={outlineId}
                    type="email"
                    name="email"
                    size="large"
                    label={t("addContact.email")}
                    minWidth="80%"
                    value={email}
                    onChange={(e) => {
                      setShowValidateEmail(false);
                      setEmail(e.target.value);
                    }}
                    onBlur={(e) => {
                      setShowValidateEmail(true);
                    }}
                  />
                </Field>
              </div>

              <div className="field-long">

                <Field
                  label={
                    <Label
                      htmlFor={outlineId}
                      style={{
                        fontSize: "1.2rem",
                        fontWeight: "bold",
                        alignSelf: "left",
                        marginBottom: "4px",
                      }}
                    >
                      {t("addContact.phone")}
                    </Label>}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                  size="large"
                >
                  <Input
                    appearance="outline"
                    id={outlineId}
                    name="phone"
                    size="large"
                    label={t("addContact.phone")}
                    minWidth="80%"
                    value={phone}
                    onChange={(e) => setPhonelength(e.target.value)}
                  />
                </Field>
              </div>
            </div>



            <div className="field-long">

              <Field
                label={
                  <Label
                    style={{
                      fontSize: "1.2rem",
                      fontWeight: "bold",
                      textAlign: "left",
                      marginBottom: "3px",
                    }}
                  >
                    {t("addContact.note")}
                  </Label>
                }
                size="large"
                style={{
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Textarea
                  onChange={setNotelength}
                  value={note}
                  size="large"
                  resize="both"
                />
              </Field>
            </div>
          </div>
        ) : (
          <div className="card-style">
            <strong>{t("addContact.contactHeader")}</strong>
            <Stack
              horizontal
              tokens={stackTokens}
              styles={{ root: { marginTop: 38.4 } }}
            >
              <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
            </Stack>
            <Stack
              horizontal
              tokens={stackTokens}
              styles={{ root: { marginTop: 28.4 } }}
            >
              <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
            </Stack>
            <Shimmer
              styles={{ root: { marginTop: 28.4 } }}
              shimmerElements={shimmerRowHeight62}
              width="610px"
            />
          </div>
        )}
        {isLoadComplete ? (
          <div className="card-style">
            <div className="field-long">
              {" "}
              <Label
                style={{
                  fontSize: "1.4rem",
                  fontWeight: "bold",
                  alignSelf: "center",
                  marginBottom: "4px",
                }}
              >
                {t("addContact.orderAndTaxInvoiceAbb")}
              </Label>
            </div>

            <div className="contact">


              <div className="field-long">

                <Field
                  label={<Label
                    htmlFor={outlineId}
                    style={{
                      fontSize: "1.2rem",

                      fontWeight: "bold",
                      alignSelf: "left",
                      marginBottom: "3px",
                    }}
                  >
                    {t("addContact.number")}&nbsp;{t("addContact.optional")}
                  </Label>}
                  style={{
                    textAlign: "left",
                    display: "flex",
                    flexDirection: "column",
                    width: "100%",
                  }}
                  size="large"
                >
                  <Input
                    appearance="outline"
                    id={outlineId}
                    name="TaxInvAbbNumber"
                    size="large"
                    minWidth="80%"
                    value={taxInvAbbNumber}
                    onChange={(e) => setTaxInvAbblength(e.target.value)}
                  />
                </Field>
              </div>
            </div>



          </div>
        ) : (
          <div className="card-style">
            <strong>{t("addContact.contactHeader")}</strong>
            <Stack
              horizontal
              tokens={stackTokens}
              styles={{ root: { marginTop: 38.4 } }}
            >
              <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
              <Shimmer shimmerElements={shimmerRowHeight32} width="300px" />
            </Stack>

          </div>
        )}
        <br />
        <br />
        <div className="responsive-boxbutton">
          <Stack horizontal style={centerItem} tokens={{ childrenGap: 30 }}  >
            <Button
              appearance="primary"
              iconProps={{ iconName: "Save" }}
              type="submit"
              value="next"
              style={{ minWidth: "120px" }}
            >
              {t("addContact.next")}
            </Button>
          </Stack>
        </div>
        <br />

      </form>

      <div style={{ color: "var(--primary-color)", fontSize: "12px", alignContent: "center", width: "100%", }}>


        <p style={{ paddingLeft: "4%", paddingRight: "4%", textAlign: "start", color: "var(--primary-color)" }} >
          {t("addContact.formConditionFooter")}
        </p>
        {
          !policyMessage.thaiMessage ? <p style={{ paddingLeft: "4%", textAlign: "start", paddingRight: "5%" }} >
            {t("addContact.warningUser")}   &nbsp;

          </p> : <p
            style={{
              paddingLeft: "4%",
              marginTop: "-5px",
              textDecoration: "underline", // Underline the text
              cursor: "pointer" // Change cursor to pointer when hovering to indicate it's clickable
            }}
            onClick={() => {
              setOnOpenDialog(true)
              setAcceptPolicy(false)
            }} // Trigger the dialog
          >
            {t("addContact.privacyPolicy")}
          </p>

        }
      </div>


      <div style={{
        display: "flex", flexDirection: "column", position: "relative",
        backgroundColor: "#ebf3fc",
        alignContent: "center", width: "100%",
        maxHeight: "80px"
      }}>

        <div style={{ marginRight: "10px", marginTop: "10px" }}>
          <img
            src={`${process.env.PUBLIC_URL}/leceiptlogo01.png`} // Using require to import the image


            alt="softwarelogo"
            style={{
              width: "65px",

              // paddingLeft:"5%",
              // paddingRight:"5%",
              marginLeft: "4%",
              borderRadius: "5%",

            }}
          />
        </div>

        <div style={{ marginLeft: "4%", marginBottom: "10px", }} >
          <label style={{ fontSize: "11px", color: "#0f6cbd" }}> {t("addContact.latestUpdate")}:&nbsp;{version}&nbsp;|&nbsp;<a style={{ color: "#0f6cbd" }} href="https://www.leceipt.com/terms-conditions" target="_blank" rel="noopener noreferrer">{t("addContact.termOfService")}</a> </label>
        </div>


      </div>





      {/* <PoweredBy /> */}
    </div>
  );
};

export default AddContacts;
